import { PropsWithChildren } from "react";
import classes from './split-section.module.scss';
import clsx from "clsx";

export type SplitSectionProps = PropsWithChildren<{
    className?: string;
}>;

export const SplitSection = ({ children, className }: SplitSectionProps) => {
    return <div className={ clsx(classes.root, className) }>
        { children }
    </div>
}
