import { useTranslation } from "react-i18next";
import Link from "next/link";
import { Typography } from "@mui/material";
import { useRouter } from "next/router";
import classes from './language-switcher.module.scss';
import clsx from "clsx";

export type LanguageSwitcherProps = {
    className?: string;
}

export const LanguageSwitcher = ({ className }: LanguageSwitcherProps) => {
    const [ t ] = useTranslation();
    const router = useRouter();

    return <div className={ clsx(classes.root, className) }>
        <Link href="/" locale="de">
            <Typography
                className={ clsx(classes.label, { [classes.labelActive]: router.locale === 'de' }) }
                component={ 'a' }
                variant={ 'body1' }>
                { t('general.language_de') }
            </Typography>
        </Link>
        <Typography component={ 'span' } className={ classes.divider } variant={ 'body1' }>/</Typography>
        <Link href="/" locale="en">
            <Typography
                className={ clsx(classes.label, { [classes.labelActive]: router.locale === 'en' }) }
                component={ 'a' }
                variant={ 'body1' }>
                { t('general.language_en') }
            </Typography>
        </Link>
    </div>
}
