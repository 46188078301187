import { ISODateString } from "next-auth/core/types";

export enum AUTH_STATUS {
    LOADING = 'loading',
    UNAUTHENTICATED = 'unauthenticated',
    AUTHENTICATED = 'authenticated',
}

declare module 'next-auth' {
    export interface Session {
        user?: {
            name?: string | null;
            email?: string | null;
            image?: string | null;
        } & {
            school_id?: string | null;
            school_name?: string | null;
        };
    }
}
