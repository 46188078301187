import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import classes from './visual-content-section.module.scss';
import { Typography } from "@mui/material";

export type VisualContentSectionProps = PropsWithChildren<{
    className?: string;
}>

export const VisualContentSection = ({ className, children }: VisualContentSectionProps) => {
    return <div className={ clsx(className, classes.root) }>
        <div className={ classes.header }></div>
        <div className={ classes.content }>
            { children }
        </div>
    </div>
}

export type VisualContentEntryProps = PropsWithChildren<{
    title?: ReactNode;
}>
export const VisualContentEntry = ({ title, children }: VisualContentEntryProps) => {
    return <div>
        <Typography variant={ 'h4' }
            className={ classes.entryTitle }
            color={ 'primary' }>
            { title }
        </Typography>
        { children }
    </div>
}
