import Image from 'next/image';
import classes from './sponsors.module.scss'
import clsx from "clsx";

export const Sponsors = () => {
    return <section className={ classes.root }>
        <div className={ classes.entry }>
            <a href={ 'https://kof.ethz.ch/ ' } target={ '_blank' } rel={ 'noreferrer' }>
                <Image src={ '/eth-zh.png' } alt={ 'ETH ZH' } width={ 128 } height={ 41 }/>
            </a>
        </div>
        <div className={ classes.entry }>
            <a href={ 'https://www.hsgym.ch/' } target={ '_blank' } rel={ 'noreferrer' }>
                <Image src={ '/hsg.svg' } alt={ 'HSG' } width={ 92 } height={ 27 }/>
            </a>
        </div>
        <div className={ classes.entry }>
            <a href={ 'https://www.ernst-goehner-stiftung.ch/' } target={ '_blank' } rel={ 'noreferrer' }>
                <Image src={ '/egs.svg' } alt={ 'EGS' } width={ 147 } height={ 41 }/>
            </a>
        </div>
        <div className={ clsx(classes.entry, classes.entryLarge) }>
            <a href={ 'https://www.ebg.admin.ch/' } target={ '_blank' } rel={ 'noreferrer' }>
                <Image src={ '/edi.svg' } alt={ 'EDI' } width={ 255 } height={ 99 }/>
            </a>
        </div>
    </section>
}
