import { AppBar, Button, CircularProgress, MenuItem, Typography } from '@mui/material';
import { PropsWithChildren, useCallback, useState } from "react";
import classes from './header.module.scss';
import { useTranslation } from "react-i18next";
import Link from "next/link";
import { signOut, useSession } from "next-auth/react";
import { AUTH_STATUS } from "../../types";
import { LanguageSwitcher } from "../language-switcher/language-switcher";
import { PAGE_OVERVIEW } from "../../routes";
import { Menu } from '../menu';


export type HeaderProps = PropsWithChildren<{
    showLanguageSwitcher?: boolean
    showUserMenu?: boolean
}>

export const Header = ({ showLanguageSwitcher = true, showUserMenu = true, children }: HeaderProps) => {
    const [ menuRef, setMenuRef ] = useState<HTMLDivElement | null>(null);
    const [ isMenuOpen, setMenuOpen ] = useState(false);
    const [ t ] = useTranslation();
    const session = useSession();

    const onLogoutClicked = useCallback(() => {
        signOut();
    }, []);

    return <AppBar color={ 'transparent' } position={ "relative" } elevation={ 0 }>
        <div className={ classes.root }>
            <div className={ classes.content }>
                <div className={ classes.titleWrapper }>
                    <Link href={ '/' }>
                        <Typography className={ classes.title } color={ 'primary' } variant="h4" component="p">
                            { t('general.title') }
                        </Typography>
                    </Link>
                </div>

                <div className={ classes.rightWrapper }>
                    { showLanguageSwitcher && <LanguageSwitcher className={ classes.switcher }/> }

                    { children }
                    { showUserMenu && <>
                        { session.status === AUTH_STATUS.LOADING && <CircularProgress/> }

                        { session.status === AUTH_STATUS.UNAUTHENTICATED && <Link href={ PAGE_OVERVIEW }>
                            <Button variant={ 'contained' } color={ 'primary' }>{ t('general.login') }</Button>
                        </Link> }

                        { session.status === AUTH_STATUS.AUTHENTICATED && <div ref={ (ref) => setMenuRef(ref) }>
                            { menuRef && <Menu open={ isMenuOpen }
                                anchor={ menuRef }
                                onClose={ () => setMenuOpen(false) }
                                trigger={ <div className={ classes.loginWrapper }>
                                    <Typography variant={ 'body1' }
                                        className={ classes.loginLabel }>
                                        { t('general.logged_in_as_label') }
                                    </Typography>

                                    <Button
                                        disableElevation
                                        variant={ 'contained' }
                                        size={ 'small' }
                                        className={ classes.button }
                                        onClick={ () => setMenuOpen(true) }>{ session.data.user.name }</Button>
                                </div> }>

                                <MenuItem component={ 'a' } target={ '_blank' }
                                    rel={ 'noreferrer' }
                                    href={ `${ process.env.NEXT_PUBLIC_API_ENDPOINT ?? 'https://api.edumap-prod.drei.io/' }admin/login` }>
                                    { t('general.admin') }
                                </MenuItem>

                                <MenuItem onClick={ onLogoutClicked }>
                                    { t('general.logout') }
                                </MenuItem>
                            </Menu> }
                        </div> }
                    </> }
                </div>
            </div>
        </div>
    </AppBar>
}
