import { PropsWithChildren } from "react";
import classes from './container.module.scss';
import clsx from "clsx";

export type ContainerProps = PropsWithChildren<{
    accent?: boolean;
    padded?: boolean;
    white?: boolean;
    className?: string;
    contentClassName?: string;
}>;

export const Container = ({ white, children, accent, padded, contentClassName, className }: ContainerProps) => {
    return <div className={ clsx(classes.root, className, {
        [classes.accent]: accent,
        [classes.padded]: padded,
        [classes.white]: white
    }) }>
        <div className={ classes.content }>
            <div className={ clsx(contentClassName, classes.wrapper) }>
                { children }
            </div>
        </div>
    </div>

}
