import { Typography } from "@mui/material";
import classes from './footer.module.scss';
import { Container } from "../container/container";
import Image from 'next/image';
import Link from "next/link";
import { useTranslation } from "react-i18next";

export type FooterProps = {
    hideLinks?: boolean;
}
export const Footer = ({ hideLinks }: FooterProps) => {
    const [ t ] = useTranslation();

    return <footer className={ classes.root }>
        <Container padded contentClassName={ classes.content }>
            <div className={ classes.left }>
                <Image className={ classes.icon } src={ '/help-icon.svg' } alt={ 'Icon' } width={ 43 } height={ 42 }/>

                <Typography className={ classes.description } color={ 'white' } variant={ 'body1' }>
                    { t('general.footer.description') }<br/>
                    <Typography className={ classes.link } href={ `mailto:${ t('general.footer.info_mail') }` }
                        component={ 'a' }>
                        { t('general.footer.info_mail') }
                    </Typography>
                </Typography>
            </div>

            { !hideLinks && <div className={ classes.linkWrapper }>
                <Link href={ '/#team' }>
                    <Typography className={ classes.link } color={ 'white' } variant="body1" component="p">
                        { t('footer.about_us') }
                    </Typography>
                </Link>
                <Link href={ '/content/faq/help/' }>
                    <Typography color={ 'white' } className={ classes.link } variant="body1" component="p">
                        { t('footer.faq') }
                    </Typography>
                </Link>
                <Typography color={ 'white' } className={ classes.link } variant="body1" component="a"
                    href={ 'mailto:edumap@kof.ethz.ch' }>
                    { t('footer.contact') }
                </Typography>
            </div> }

            <div className={ classes.logoWrapper }>
                <Link href={ '/' }>
                    <Typography className={ classes.title } color={ 'white' } variant="h4" component="p">
                        { t('general.title') }
                    </Typography>
                </Link>
            </div>
        </Container>
    </footer>
}
