import { Typography } from "@mui/material";
import { SplitSection } from "../split-section/split-section";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import classes from './intro-teaser.module.scss';

export const IntroTeaser = () => {
    const [ t ] = useTranslation();

    return (<>
        <Typography color={ 'primary' } mb={ 4 } variant={ 'h4' } component={ 'h1' }>
            { t('pages.main.mainTitle') }
        </Typography>

        <SplitSection className={ classes.splitSection }>
            <div className={ classes.sectionLeft }>
                <Typography className={ classes.introText } variant={ 'h6' } color={ 'primary' }
                    component={ 'p' }
                    dangerouslySetInnerHTML={ { __html: t('pages.main.entrySection.text1') } }/>

                <Typography className={ classes.introText } variant={ 'h6' } color={ 'primary' }
                    component={ 'p' } dangerouslySetInnerHTML={ { __html: t('pages.main.entrySection.text2') } }>
                </Typography>
            </div>
            <div className={ classes.circularChartVisual }>
                <Image src={ '/chart.svg' } width={ 316 } height={ 316 }/>
                <Typography className={ classes.circularChartVisualLegend } fontWeight={ 'bold' }>
                    { t('pages.main.circularChartLegend') }
                </Typography>
                <Typography>
                    { t('pages.main.circularChartSource') }
                </Typography>
            </div>
        </SplitSection>
    </>)
}
